// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attraction-js": () => import("./../../../src/pages/attraction.js" /* webpackChunkName: "component---src-pages-attraction-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-charisma-js": () => import("./../../../src/pages/charisma.js" /* webpackChunkName: "component---src-pages-charisma-js" */),
  "component---src-pages-confidence-js": () => import("./../../../src/pages/confidence.js" /* webpackChunkName: "component---src-pages-confidence-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-style-js": () => import("./../../../src/pages/style.js" /* webpackChunkName: "component---src-pages-style-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-views-post-template-index-js": () => import("./../../../src/views/postTemplate/index.js" /* webpackChunkName: "component---src-views-post-template-index-js" */)
}

